import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'dwa-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent {
	title = 'dewey-app';

	constructor(private translate: TranslateService) {
		this.translate.setDefaultLang('en');
		this.translate.use('en');
	}
}

<div class="px-2 py-1.5 text-xs leading-6 text-gray-500">
	{{ sectionTitle }}
</div>
<ul role="list">
	<li
		*ngFor="let item of section.items"
		dwa-sidebar-item
		(remove)="remove.emit($event)"
		[item]="item"></li>
</ul>

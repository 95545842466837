<div class="fixed inset-0 left-0 w-14 border-gray-300 bg-gray-200">
	<div class="flex h-full flex-col">
		<div
			class="flex size-14 flex-none shrink-0 items-center justify-center">
			<button
				type="button"
				class="-m-2.5 p-2.5 text-gray-700"
				(click)="sidebarService.toggleSidebar()">
				<span class="sr-only">Open sidebar</span>
				<dwa-menu-icon [classList]="['size-6']"></dwa-menu-icon>
			</button>
		</div>
		<nav class="grow">
			<ul class="flex flex-col items-center">
				<li *ngFor="let item of items" class="size-14">
					<dwa-navigation-item [item]="item"></dwa-navigation-item>
				</li>
			</ul>
		</nav>
		<div
			class="mb-2 flex flex-none justify-center"
			*ngIf="userService.currentUser">
			<dwa-user-avatar
				[name]="userService.currentUser.fullName"></dwa-user-avatar>
		</div>
	</div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/views/main-layout/main-layout.component';
import { authGuard } from './auth/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'chat',
	},
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [authGuard],
		children: [
			{
				path: 'chat',
				loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
			},
		],
	},
	{ path: '**', redirectTo: 'chat' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}

<a
	[routerLink]="['chat', item.uuid]"
	class="group/link relative my-1 flex gap-x-3 rounded-md p-2 text-sm leading-6 hover:bg-gray-200"
	[routerLinkActive]="'bg-gray-200'"
	#rla="routerLinkActive"
	[routerLinkActiveOptions]="{ exact: true }">
	<span class="truncate">{{ item.text }}</span>
	<span class="group/button absolute right-0 mr-2">
		<button
			*ngIf="!rla.isActive"
			(click)="onRemove($event)"
			type="button"
			class="invisible inline-flex items-center gap-x-1.5 rounded-md bg-gray-300 px-1.5 py-1.5 text-sm font-semibold text-gray-600 hover:visible hover:border-gray-400 group-hover/link:visible group-hover/button:bg-gray-400 group-hover/button:text-gray-400">
			<ng-icon name="hugeCancel02" class="text-gray-100"></ng-icon>
		</button>
	</span>
</a>

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AmplifyAuthenticator } from '../authenticators/amplify-authenticator';
import { BaseAuthenticator } from '../authenticators/base-authenticator';

@Injectable({
	providedIn: 'root',
})
export class AuthenticatorService {
	private selectedAuthenticator?: 'amplify';

	constructor() {}

	public get authenticator(): BaseAuthenticator | null {
		if (environment.auth) {
			this.selectedAuthenticator = environment.auth.authenticator;
			switch (this.selectedAuthenticator) {
				case 'amplify':
					return new AmplifyAuthenticator();
				default:
					throw new Error(`Authenticator ${this.selectedAuthenticator} not exists!`);
			}
		} else {
			return null;
		}
	}
}

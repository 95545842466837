import { Component, Input } from '@angular/core';
import { INavigationItem } from '../../types/navigation.types';

@Component({
	selector: 'dwa-navigation-item',
	templateUrl: './navigation-item.component.html',
	styleUrl: './navigation-item.component.scss',
})
export class NavigationItemComponent {
	@Input() public item!: INavigationItem;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './containers/navigation/navigation.component';
import { SharedModule } from '../shared/shared.module';
import { MainLayoutComponent } from './views/main-layout/main-layout.component';
import { NavigationService } from './services/navigation.service';
import { SidebarComponent } from './containers/sidebar/sidebar.component';
import { SidebarService } from './services/sidebar.service';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { SidebarSectionComponent } from './components/sidebar-section/sidebar-section.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';

@NgModule({
	declarations: [
		NavigationComponent,
		MainLayoutComponent,
		SidebarComponent,
		SidebarItemComponent,
		SidebarSectionComponent,
		NavigationItemComponent,
	],
	imports: [CommonModule, SharedModule],
	providers: [NavigationService, SidebarService],
})
export class LayoutModule {}

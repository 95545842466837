import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	private static FALLBACK_BRAND_URL = '/assets/img/brand.svg';

	public get brandLogo(): string {
		if (environment.theme && environment.theme.brandLogo) {
			return environment.theme.brandLogo;
		}
		return LayoutService.FALLBACK_BRAND_URL;
	}
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../../../environments/environment';
import { Amplify, ResourcesConfig } from "aws-amplify";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

	constructor() {
		const amplifyConfig = environment.auth?.amplify;
		if (amplifyConfig) {
			Amplify.configure({
				Auth: {
					Cognito: amplifyConfig,
				},
			} as ResourcesConfig);
		}
	}

	async handle(request: HttpRequest<unknown>, next: HttpHandler) {
		const session = await fetchAuthSession();
		request = request.clone({
			setHeaders: {
				Authorization: `Bearer ${session?.tokens?.idToken?.toString()}`,
				'Content-Type': 'application/json',
			},
		});

		return lastValueFrom(next.handle(request));
	}

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!environment.auth) {
			return next.handle(request);
		}
		return from(this.handle(request, next));
	}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISideBarItem } from '../../types/sidebar.types';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'li[dwa-sidebar-item]',
	templateUrl: './sidebar-item.component.html',
	styleUrl: './sidebar-item.component.scss',
})
export class SidebarItemComponent {
	@Input() public item!: ISideBarItem;
	@Output() public remove: EventEmitter<string>;
	constructor() {
		this.remove = new EventEmitter<string>();
	}

	public onRemove(event: Event): void {
		event.stopPropagation();
		event.preventDefault();
		this.remove.emit(this.item.uuid);
	}
}

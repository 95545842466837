import { Component } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';

@Component({
	selector: 'dwa-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {
	constructor(public sidebarService: SidebarService) {}
}

import { environment } from '../../../environments/environment';
import { BaseAuthenticator } from './base-authenticator';

import { Amplify, ResourcesConfig } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { signInWithRedirect } from 'aws-amplify/auth';

export class AmplifyAuthenticator extends BaseAuthenticator {
	/**
	 * AmplifyAuthenticator is just used for the current use case - TODO: REMOVE ALL AMPLIFY STUFF LATER - MH
	 */

	constructor() {
		super();
		this.loadAmplify();
	}
	private loadAmplify() {
		const amplifyConfig = environment.auth?.amplify;
		if (amplifyConfig) {
			Amplify.configure({
				Auth: {
					Cognito: amplifyConfig,
				},
			} as ResourcesConfig);
		}
	}

	private async getAuthenticatedUser() {
		const { username, signInDetails } = await getCurrentUser();

		const { tokens: session } = await fetchAuthSession();

		// Note that session will no longer contain refreshToken and clockDrift
		return {
			username,
			session,
			authenticationFlowType: signInDetails?.authFlowType,
		};
	}

	public async authenticate(): Promise<boolean> {
		try {
			await this.getAuthenticatedUser();
			return true;
		} catch {
			await signInWithRedirect();
			return false;
		}
	}
}

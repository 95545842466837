import { Component } from '@angular/core';
import { INavigationItem } from '../../types/navigation.types';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../../shared/services/user.service';
import { SidebarService } from '../../services/sidebar.service';

@Component({
	selector: 'dwa-navigation',
	templateUrl: './navigation.component.html',
	styleUrl: './navigation.component.scss',
})
export class NavigationComponent {
	constructor(
		private navigationService: NavigationService,
		public sidebarService: SidebarService,
		public userService: UserService
	) {}

	public get items(): Array<INavigationItem> {
		return this.navigationService.navigationItems;
	}
}

<a
	*ngIf="!item.disabled"
	[routerLink]="item.link"
	class="group flex size-14 content-center items-center justify-center p-3 text-sm font-semibold leading-6"
	routerLinkActive
	#rla="routerLinkActive"
	[ngClass]="
		rla.isActive
			? 'bg-gray-300 text-primary-400'
			: item.disabled
				? ''
				: 'text-gray-400 hover:bg-gray-300 hover:text-primary-400'
	">
	<ng-icon [name]="item.icon" [size]="'28'"></ng-icon>
</a>
<a
	*ngIf="item.disabled"
	class="group flex size-14 cursor-not-allowed content-center items-center justify-center p-3 text-sm font-semibold leading-6 text-gray-400">
	<ng-icon [name]="item.icon" [size]="'28'"></ng-icon>
</a>

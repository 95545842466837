import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticatorService } from '../services/authenticator.service';

export const authGuard: CanActivateFn = () => {
	if (!environment.auth) {
		return true;
	} else {
		const activatedAuthenticator = inject(AuthenticatorService).authenticator;
		if (!activatedAuthenticator) {
			return true;
		} else {
			return activatedAuthenticator.authenticate();
		}
	}
};

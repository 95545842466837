<div class="size-full">
	<dwa-navigation> </dwa-navigation>
	<div class="ml-14 h-full transition-all">
		<dwa-sidebar></dwa-sidebar>
		<main
			class="h-full transition-all md:ml-0"
			[ngClass]="{ 'lg:ml-80': sidebarService.isSidebarOpen }">
			<router-outlet></router-outlet>
		</main>
	</div>
</div>

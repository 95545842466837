import { Injectable } from '@angular/core';
import { INavigationItem } from '../types/navigation.types';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private _navigationItems: Array<INavigationItem> = [
		{
			name: 'Chatbot',
			link: '/chat',
			icon: 'hugeMessageMultiple01',
			disabled: false,
		},
		{
			name: 'Files',
			link: '/files',
			icon: 'hugeFile01',
			disabled: true,
		},
		{
			name: 'Knowledge-Bases',
			link: '/knowledge-bases',
			icon: 'hugeDatabase',
			disabled: true,
		},
	];

	public get navigationItems(): Array<INavigationItem> {
		return this._navigationItems;
	}
}
